<script>
import Layout from "../../layouts/main";
const formatController = require('@/controllers/endpoint/format')
const clientController = require('@/controllers/endpoint/clients')

/**
 * Checkout Component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {
        formatData:{           
            "name": "",
            "text": "",
            "value": "",
            "description": ""
        },
        clientData:{
            "email":"",
            "password":""
        }
    };
  },
    computed: {
    disabledFormat() {
      return (
        this.formatData.name == "" ||
        this.formatData.description == ""
      );
    },
    disabledClient() {
      return (
        this.clientData.email == "" ||
        this.clientData.password == ""
      );
    },
  },
  methods: {
    alert(message, variant){
          this.$bvToast.toast(`${message}`, {
        title: "BRAND ALERT",
        variant: variant,
        solid: true
      });
    },
    openModal(ref) {
      this.$refs[ref].show();
    },
    closeModal(ref) {
      this.$refs[ref].hide();
    },
    addFormat(){
        let name =  this.formatData.name.trim()
        name =  this.formatData.name.toLowerCase()
        name =  this.formatData.name.charAt(0).toUpperCase() + this.formatData.name.slice(1);
        let description = this.formatData.description =  this.formatData.description.trim();
        description = this.formatData.description.charAt(0).toUpperCase() + this.formatData.description.slice(1);

        let data = {
             "name":  name,
             "text":  name,
             "value": name,
             "description": description
        }

        const newFormat = formatController.create(data);
        newFormat.then((response) => {   //Succesfully result
        let data = response.data.description;
        this.closeModal("add-format-modal")
        this.alert(data, 'success')
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    addClient(){
        const newClient = clientController.createUser(this.clientData);
        newClient.then((response) => {   //Succesfully result
        let data = response.data.description;
        this.closeModal("add-client-modal")
        this.alert(data, 'success')
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    }
  }
      
};
</script>

<template>
  <Layout>
      <div class="col-xl-12">
      <h5 class="title-home">Administrator<span class="dot-home">.</span></h5>
      </div>
      <div style="padding-left:30px;">
        <div class="col-xl-12" style="margin-bottom:35px;">
       <tbody >
            <tr>
              <td>
                <router-link
                tag="a"
                :to="`/register/user`"
                class="nav-link dropdown-toggle arrow-none"
              >
                <div class="third-box-home">
                Create new user
                </div>
              </router-link>
              </td>
              <td>
                <div v-on:click="openModal('add-client-modal')" style="margin-left:10px;" class="third-box-home">
                Create new client
                </div>
              </td>
               <td>
                 <div v-on:click="openModal('add-format-modal')" style="margin-left:25px;" class="third-box-home">
                Create new format
                </div>
              </td>
            </tr>
        </tbody>
        </div>
         <b-modal ref="add-format-modal" title="New format for creatives" hide-footer centered>
              <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="name">Name<span style="color:#247ba0">*</span></label>
                      <input v-model="formatData.name" type="text" class="form-control" id="name" placeholder="Enter Name" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="description">Description<span style="color:#247ba0">*</span></label>
                      <input v-model="formatData.description" type="text" class="form-control" id="description" placeholder="Enter Description" />
                    </div>
                  </div>
                     <div class="col-lg-12">
                    <button
                  v-on:click="addFormat()"
                  :disabled="disabledFormat"
                  class="btn button-client-form btn-primary"
                >
                  ADD NEW
                </button>
                  </div>
                </div>
         </b-modal>
         <b-modal ref="add-client-modal" title="New client" hide-footer centered>
            <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="name">Email<span style="color:#247ba0">*</span></label>
                      <input v-model="clientData.email" type="email" class="form-control" id="name" placeholder="Enter Email" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="description">Password<span style="color:#247ba0">*</span></label>
                      <input v-model="clientData.password" type="password" class="form-control" id="description" placeholder="Enter Password" />
                    </div>
                  </div>
                     <div class="col-lg-12">
                    <button
                  v-on:click="addClient()"
                  :disabled="disabledClient"
                  class="btn button-client-form btn-primary"
                >
                  ADD NEW
                </button>
                  </div>
                </div>
         </b-modal>
      </div>
      </Layout>
</template>
<style scoped>
.title-home{
  font-size: 40px;
  margin: 0 0 0px 0;
  color: #011c27;
  margin-bottom: 30px;
  padding-left:30px;
  font-family: nunito;
  font-weight: 600;
}
.dot-home{
  color:#247ba0;
  font-family: nunito;
  font-weight: 600;

}
.box-home{
    font-weight: 600;
    background-color: #011c27;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
}
.text-box-home{
      font-family: nunito;
      color: #595959;
      font-weight: 600;
      padding-left:10px;
}
.second-box-home{
    margin-left:30px;
    background-color: #011c27;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    font-weight: 600;

}
.third-box-home{
    background-color: #ededed;
    color: #011c27;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    cursor:pointer;
}
.third-box-home:hover{
    background-color: #011c27;
    color: #ededed;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
}
.fourth-box-home{
    margin-left:30px;
    background-color: #ededed;
    color: #011c27;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
          font-weight: 600;

    font-size: 20px;
}
</style>